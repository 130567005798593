import { Typography, MediaImageStandard } from 'components/Contentful'
import {
  Box,
  Button,
  Container,
  LinearProgress,
  CardMedia as AscendCardMedia,
  Chip,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@achieve/ascend'
import styles from './DebtQuiz.module.scss'
import { Check, Plus, X } from 'react-feather'
import Info from 'components/Svgs/Info'
import { useContext, useState } from 'react'
import QuizResultPage from './QuizResultPage'
import { GlobalContext } from 'providers/GlobalProvider'

const Quiz = ({
  questionGroup1,
  questionGroup2,
  questionGroup3,
  questionGroup4,
  maxScoreValue,
  debtQuizId,
  TriggerNewPageEvent,
  TriggerResult,
  TriggerResultPage,
}) => {
  const { dispatch } = useContext(GlobalContext)
  const [questionNumber, setQuestionNumber] = useState(1)
  const [currentTopicIndex, setCurrentTopicIndex] = useState(0)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [score, setScore] = useState(0)
  const [currentResponse, setCurrentResponse] = useState([])
  const [modalStatus, setModalStatus] = useState(false)
  const [showResults, setShowResults] = useState(false)
  const [resultList, setResultList] = useState([])
  const topics = [
    {
      topic: questionGroup1?.title,
      icon: questionGroup1?.secondaryIcon,
      color: questionGroup1?.color,
      questions: questionGroup1?.questionsCollection?.items?.map((question) => {
        return {
          question: question?.title,
          moreInfo: question?.moreInfoText?.json,
          questionType: question?.questionType,
          valueType: question?.valueType,
          options: question?.answerList?.jsonContent?.answers,
        }
      }),
    },
    {
      topic: questionGroup2.title,
      icon: questionGroup2?.secondaryIcon,
      color: questionGroup2?.color,
      questions: questionGroup2?.questionsCollection?.items?.map((question) => {
        return {
          question: question?.title,
          moreInfo: question?.moreInfoText?.json,
          questionType: question?.questionType,
          valueType: question?.valueType,
          options: question?.answerList?.jsonContent?.answers,
        }
      }),
    },
    {
      topic: questionGroup3?.title,
      icon: questionGroup3?.secondaryIcon,
      color: questionGroup3?.color,
      questions: questionGroup3?.questionsCollection?.items?.map((question) => {
        return {
          question: question?.title,
          moreInfo: question?.moreInfoText?.json,
          questionType: question?.questionType,
          valueType: question?.valueType,
          options: question?.answerList?.jsonContent?.answers,
        }
      }),
    },
    {
      topic: questionGroup4?.title,
      icon: questionGroup4?.secondaryIcon,
      color: questionGroup4?.color,
      questions: questionGroup4?.questionsCollection?.items?.map((question) => {
        return {
          question: question?.title,
          moreInfo: question?.moreInfoText?.json,
          questionType: question?.questionType,
          valueType: question?.valueType,
          options: question?.answerList?.jsonContent?.answers,
        }
      }),
    },
  ]

  const currentTopic = topics[currentTopicIndex]
  const currentQuestion = currentTopic.questions[currentQuestionIndex]
  const handleAnswer = (answer, event) => {
    setCurrentResponse([])
    let newScore = score
    let answerList = []
    answer.map((ans) => {
      currentQuestion?.options.map?.((option) => {
        if (option.title == ans) {
          answerList.push(option?.title)
          newScore = newScore + option?.value
        }
      })
    })
    setScore(currentQuestion?.valueType == 'negative' ? 100 + newScore : newScore)
    TriggerResult(questionNumber, answerList.join(', '), event)
    if (currentQuestionIndex < currentTopic.questions.length - 1) {
      TriggerNewPageEvent(questionNumber + 1, event)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    } else if (currentTopicIndex < topics.length - 1) {
      TriggerNewPageEvent(questionNumber + 1, event)
      setCurrentTopicIndex(currentTopicIndex + 1)
      setCurrentQuestionIndex(0)
    } else {
      const body = {
        result: (newScore / maxScoreValue) * 100,
        debtQuizId,
      }
      setShowResults(true)
      fetch('/api/debt-quiz/get-result', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }).then(async (response) => {
        const contentfulJson = await response.json()
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        setTimeout(() => {
          if (contentfulJson?.mainEncryptionKey) {
            // mainEncryptionKey is only used for Dashboard login circle inside FAQ and they need this cookie to uncrypt the data
            document.cookie = `${contentfulJson?.mainEncryptionKey}`
          }
          setResultList(contentfulJson?.pageSections)
          TriggerResultPage(contentfulJson?.result, contentfulJson?.resultValue, event)
          if (contentfulJson?.pageDisclosure) {
            //set new page disclosure
            dispatch({
              type: 'SET_PAGE_DISCLOSURE',
              payload: contentfulJson?.pageDisclosure,
            })
          }
        }, 1500)
      })
    }
    setQuestionNumber(questionNumber + 1)
  }
  const handleCurrrentResponse = (response) => {
    if (currentQuestion?.questionType == 'Single') {
      setCurrentResponse(response == currentResponse?.[0] ? [] : [response])
    } else {
      setCurrentResponse(
        currentResponse.includes(response)
          ? currentResponse.filter((e) => e !== response)
          : [...currentResponse, response]
      )
    }
  }

  return showResults ? (
    <QuizResultPage resultList={resultList} />
  ) : (
    <Container>
      <div className={styles['topic-container']}>
        {questionGroup1?.mainIcon && (
          <AscendCardMedia className={styles['icon-container']} data-testid="topic-media">
            <MediaImageStandard
              data-testid="card-media-image"
              layout="fixed"
              className="w-[29px] lg:w-[45px] h-[29px] lg:h-[45px]"
              content={questionGroup1?.mainIcon}
              alt={questionGroup1?.mainIcon?.title || 'primary-icon-1'}
            />
            <Typography
              variant="bodyMd"
              fontWeight="medium"
              className={currentTopic.topic == questionGroup1?.title && styles['selected-topic']}
              content={questionGroup1?.title}
            />
          </AscendCardMedia>
        )}
        {questionGroup2?.mainIcon && (
          <AscendCardMedia className={styles['icon-container']} data-testid="topic-media">
            <MediaImageStandard
              data-testid="card-media-image"
              layout="fixed"
              className="w-[29px] lg:w-[45px] h-[29px] lg:h-[45px]"
              content={questionGroup2?.mainIcon}
              alt={questionGroup2?.mainIcon?.title || 'primary-icon-2'}
            />
            <Typography
              variant="bodyMd"
              fontWeight="medium"
              className={currentTopic.topic == questionGroup2?.title && styles['selected-topic']}
              content={questionGroup2?.title}
            />
          </AscendCardMedia>
        )}
        {questionGroup3?.mainIcon && (
          <AscendCardMedia className={styles['icon-container']} data-testid="topic-media">
            <MediaImageStandard
              data-testid="card-media-image"
              layout="fixed"
              className="w-[29px] lg:w-[45px] h-[29px] lg:h-[45px]"
              content={questionGroup3?.mainIcon}
              alt={questionGroup3?.mainIcon?.title || 'primary-icon-3'}
            />
            <Typography
              variant="bodyMd"
              fontWeight="medium"
              className={currentTopic.topic == questionGroup3?.title && styles['selected-topic']}
              content={questionGroup3?.title}
            />
          </AscendCardMedia>
        )}
        {questionGroup4?.mainIcon && (
          <AscendCardMedia className={styles['icon-container']} data-testid="topic-media">
            <MediaImageStandard
              data-testid="card-media-image"
              layout="fixed"
              className="w-[29px] lg:w-[45px] h-[29px] lg:h-[45px]"
              content={questionGroup4?.mainIcon}
              alt={questionGroup4?.mainIcon?.title || 'primary-icon-4'}
            />
            <Typography
              variant="bodyMd"
              fontWeight="medium"
              className={currentTopic.topic == questionGroup4?.title && styles['selected-topic']}
              content={questionGroup4?.title}
            />
          </AscendCardMedia>
        )}
      </div>
      <LinearProgress
        variant="determinate"
        value={((currentTopicIndex * 3 + (currentQuestionIndex + 1)) / (topics.length * 3)) * 100}
      />
      <Box mt={4} mb={2} className={styles['chip-container']}>
        <Chip
          label={
            <Typography
              className="bodySm lg:bodyLg"
              fontWeight="bold"
              content={currentTopic.topic}
            />
          }
          className={styles['topic-chip']}
          data-color={currentTopic.color}
          avatar={
            <Avatar
              src={currentTopic?.icon?.url}
              height="20"
              width="20"
              variant="square"
              alt={currentTopic?.icon?.title || 'secondary-icon-image'}
            />
          }
        />
      </Box>
      <Box mt={4} mb={2} className={styles['chip-container']}>
        <div>
          <Typography content={`${currentQuestion.question}`} className="headingSm lg:headingMd" />
          {currentQuestion.moreInfo && (
            <>
              <IconButton
                onClick={() => {
                  setModalStatus(true)
                }}
              >
                <Info className={styles['info-btn']} />
              </IconButton>

              <Dialog
                open={modalStatus}
                onClose={() => {
                  setModalStatus(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogTitle id="alert-dialog-title" className={styles['close-title']}>
                    <IconButton
                      onClick={() => {
                        setModalStatus(false)
                      }}
                    >
                      <X />
                    </IconButton>
                  </DialogTitle>
                  <Typography variant="bodyMd" content={currentQuestion.moreInfo} />

                  <Button
                    onClick={() => {
                      setModalStatus(false)
                    }}
                    className={styles['got-it-button']}
                    color="secondary"
                    variant="contained"
                  >
                    Got it
                  </Button>
                </DialogContent>
              </Dialog>
            </>
          )}
          {currentQuestion?.questionType == 'Multiple' && (
            <div className={styles['multiple-disclaimer']}>
              <Typography variant="bodySm" content="Select all that apply" />
            </div>
          )}
        </div>
      </Box>
      <Box
        mt={2}
        mb={4}
        display={currentQuestion?.questionType == 'Single' ? 'flex' : 'ruby-text'}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className={
          currentQuestion?.questionType == 'Single'
            ? styles['answers-list']
            : styles['answers-list-multiple']
        }
      >
        {currentQuestion.options.map((option, index) => (
          <Button
            key={index}
            variant={currentResponse.includes(option.title) ? 'contained' : 'outlined'}
            color={currentResponse.includes(option.title) ? 'secondary' : 'info'}
            onClick={() => handleCurrrentResponse(option.title)}
            sx={{ m: 1 }}
          >
            <Typography variant="bodyMd" content={option.title} />
            {currentQuestion?.questionType == 'Multiple' &&
              (currentResponse.includes(option.title) ? <Check /> : <Plus />)}
          </Button>
        ))}
      </Box>
      <Box
        mt={2}
        mb={4}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className={styles['continue-button']}
      >
        <Button
          color="primary"
          variant="contained"
          disabled={!currentResponse[0]}
          onClick={(e) => handleAnswer(currentResponse, e)}
          sx={{ m: 1 }}
        >
          <Typography variant="labelLg" content="Continue" fontWeight="bold" />
        </Button>
      </Box>
    </Container>
  )
}

export default Quiz

import styles from './HeroMedia.module.scss'
import { Box } from '@achieve/ascend'
import { MediaImageStandard } from 'components/Contentful'
import { debounce as _debounce } from 'lodash-es'
import useIsSsr from 'hooks/useIsSsr'
import { useEffect, useState } from 'react'

function HeroMedia({
  className,
  image,
  mobileImage,
  mobileVideo,
  video,
  alt,
  videoRef,
  thumbnail,
  children,
}) {
  const isSsr = useIsSsr()
  const [onLandscape, setOnLandscape] = useState(true)

  useEffect(() => {
    setOnLandscape(window.innerWidth > window.innerHeight)

    const handleResize = _debounce(
      () => {
        setOnLandscape(window.innerWidth > window.innerHeight)
      },
      250,
      { maxWait: 250 }
    )
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isSsr])

  useEffect(() => {
    videoRef.current?.load()
  }, [video, videoRef])

  const VideoComponent = ({ videoSrc }) => (
    <Box className={className} data-on-landscape={onLandscape}>
      <video
        autoPlay
        muted
        playsInline
        loop
        id="play-video"
        className={styles['video']}
        ref={videoRef}
        poster={thumbnail ? thumbnail?.replace('//', '') : null}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
      <Box className={styles['hero-media-content-video']}>{children}</Box>
    </Box>
  )

  if (!video)
    return (
      <Box className={className}>
        <div className="block lg:hidden">
          <MediaImageStandard
            className={styles['image']}
            content={mobileImage ?? image}
            alt={alt}
            layout="fill"
            objectFit="cover"
            objectPosition="left top"
            priority
          />
        </div>
        <div className="hidden lg:block">
          <MediaImageStandard
            className={styles['image']}
            content={image ?? mobileImage}
            alt={alt}
            layout="fill"
            objectFit="cover"
            objectPosition="left top"
            priority
          />
        </div>
        <Box className={styles['hero-media-content-image']}>{children}</Box>
      </Box>
    )

  if (!mobileVideo) return <VideoComponent videoSrc={mobileVideo} />
  return <VideoComponent videoSrc={video} />
}

export { HeroMedia }

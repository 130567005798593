import { useMemoizedContentGetter } from 'utils/contentful'
import { Typography } from 'components/Contentful'
import Section from 'components/Section'
import { Box } from '@achieve/ascend'
import styles from './AboutUsHero.module.scss'
import classNames from 'classnames'

const AboutUsHero = ({ content }) => {
  const { aboutUsHeroTitle, aboutUsHeroSubTitle, aboutUsHeroImage } = useMemoizedContentGetter(
    content,
    ['aboutUsHeroTitle', 'aboutUsHeroSubTitle', 'aboutUsHeroImage']
  )
  return (
    <Section
      data-testid="about-us-hero-section"
      className={styles['section']}
      contain={false}
      mediaContent={aboutUsHeroImage?.mediaContent}
      middleMediaContent={aboutUsHeroImage?.mobileMediaContent}
      mobileMediaContent={aboutUsHeroImage?.mobileMediaContent}
      full
    >
      <Box className={styles['about-us-hero-container']} data-testid="about-us-hero-background">
        <div className={styles['media-img']}></div>
        <div className={styles['content']}>
          <div className={styles['info']}>
            <Typography
              content={aboutUsHeroTitle?.textContent}
              className={classNames(
                styles['title'],
                'headingLg lg:displayXl font-bold text-neutral-0 lg:text-neutral-900'
              )}
              data-testid="about-us-hero-title"
            />
            <Typography
              content={aboutUsHeroSubTitle?.textContent}
              data-testid="about-us-hero-subtitle"
              className={classNames(
                styles['subtitle'],
                'headingMd font-light lg:font-medium text-neutral-0 lg:text-neutral-900'
              )}
            />
          </div>
        </div>
      </Box>
    </Section>
  )
}
export { AboutUsHero }
export default AboutUsHero

import styles from './ValueProps.module.scss'
import { Grid, Box, Collapse, AchieveTheme } from '@achieve/ascend'
import Section from 'components/Section'
import { CardValueProp } from 'components/Cards'
import { TitleDivider } from 'components/TitleDivider'
import { BLOCKS } from '@contentful/rich-text-types'
import { LinkButton, Typography, MediaImageStandard } from 'components/Contentful'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { useMemoizedContentGetter } from 'utils/contentful'
import classNames from 'classnames'
import { Carousel } from 'components/Carousels'
import { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Button } from 'components/Button'

function Card({ item: { cardImage, cardTitleText, cardText }, priority }) {
  // we are using anything smaller than lg as our mobile for this one
  // for now though, we will make a medium screen resolution later
  // *** UPDATE 7/8/22 ***
  // The lg breakpoint was changed (now 1024px) and xl (1280) is closer to the old lg (1200).
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const [quote] = cardText?.content ?? []
  return (
    <Grid
      container
      direction={{ xs: 'column-reverse' }}
      justifyContent={{ xs: 'flex-end' }}
      alignItems={{ xs: 'left' }}
    >
      <Grid
        item
        xs={6}
        className={
          // styles['value-card-grid-carousel']
          'py-0 px-4 lg:[&_data-[no-margin="false"]]:p-0'
        }
      >
        <div
          className={
            // styles['image-container']
            'aspect-square h-[100px]'
          }
        >
          <MediaImageStandard
            layout={'fixed'}
            content={cardImage}
            priority={priority}
            width={60}
            height={60}
          />
        </div>
        <Typography
          className={
            // styles['carousel-item-title']
            'pt-4 first:pt-4 not-first:pt-0 '
          }
          content={cardTitleText}
          fontWeight="bold"
          variant={isMobile ? 'displayMd' : 'displayLg'}
        />
        <Typography
          className={
            'pr-8 pt-4'
          }
          content={quote}
          fontWeight="regular"
          variant="bodySm"
        />
      </Grid>
    </Grid>
  )
}

function ValuePropsModule({
  idContent,
  title,
  eyebrow,
  subtitle,
  grid,
  event,
  theme,
  linkButton,
  arrow,
  handwritten,
  backgroundConfigJson,
  componentVariant,
  disclaimer,
}) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const jsonContent = backgroundConfigJson?.jsonContent || {}
  const backgroundColor = theme?.theme[0]
    ? AchieveTheme?.ascend?.colors?.blue?.[50]
    : AchieveTheme?.ascend?.colors?.neutral?.[0]

  const titleVariant = jsonContent?.titleVariant ?? 'displayLg'
  const titleVariantMobile = jsonContent?.titleVariantMobile ?? 'headingMd'
  const subTitleVariant = jsonContent?.subTitleVariant ?? 'displaySm'
  const subTitleVariantMobile = jsonContent?.subTitleVariantMobile ?? 'bodySm'
  const variant = {
    title: isMobile ? titleVariantMobile : titleVariant,
    titleFontWeight: jsonContent?.titleFontWeight ?? 'bold',
    subtitle: isMobile ? subTitleVariantMobile : subTitleVariant,
    subtitleFontWeight: jsonContent?.subtitleFontWeight ?? 'regular',
  }

  const breakline = ['aboutUsCulture', 'valueSeparatorProps'].includes(componentVariant)
  const containerGrid = !['aboutUsCulture', 'partnersValueProps'].includes(componentVariant)

  const itemsByrow = jsonContent?.itemsByrow || 3
  const carouselOnMobile = jsonContent?.carouselOnMobile || false
  const dividerOnMobile = jsonContent?.dividerOnMobile || false
  const marginOnMobile = jsonContent?.marginOnMobile ?? true
  const dividerToggle =
    jsonContent?.dividerToggle || jsonContent?.dividerToggle == false
      ? jsonContent?.dividerToggle
      : true
  const buttonColor = jsonContent?.buttonColor
  const cardStyle = jsonContent?.cardStyle
  const maxMobileItems = jsonContent?.maxMobileItems || 0
  const navLinkSection = jsonContent?.navLinkSection
  const [showMore, setShowMore] = useState(false)
  const gridConfig = jsonContent?.gridConfig || {}
  const getGridItem = (id, item, dividerOnMobile) => {
    return (
      <Grid
        item
        md={12}
        lg={3.5}
        xs={12}
        data-item-id={id}
        className={classNames(
          (id + 1) % itemsByrow == 0 || !dividerToggle
            ? ''
            : // styles['grid-item']
              'border-none lg:border-r lg:border-solid lg:border-y-0 lg:border-l-0 lg:border-[#42546b] lg:pr-4',
          id + 1 > itemsByrow &&
            // styles['spacing-grid-item']
            'pt-0 lg:pt-[48px]'
        )}
        key={`card-${id}`}
      >
        <Box
          data-testid="value-prop-grid-card-item"
          className={
            // styles['value-prop-grid-card']
            'lg:max-w-[305px] lg:m-auto'
          }
        >
          <CardValueProp
            cardStyle={cardStyle}
            content={item}
            isMobile={isMobile}
            breakline={breakline}
            dividerOnMobile={dividerOnMobile}
            pos={id}
            className={
              // styles['card-container']
              ''
            }
            gridConfig={gridConfig}
          />
        </Box>
      </Grid>
    )
  }

  const gridItems = (dividerOnMobile = false, max) => {
    return (
      <>
        {grid?.gridItemsCollection?.items?.map((item, idx) => {
          if (idx >= max && max !== 0) return null
          return getGridItem(idx, item, dividerOnMobile)
        })}
        {max > 0 && grid?.gridItemsCollection?.items.length > max && (
          <>
            <Collapse in={showMore} data-testid="collapsed-container" className="w-full">
              <Grid container justifyContent="left" gap="1rem">
                {grid?.gridItemsCollection?.items?.map((item, idx) => {
                  if (idx < max) return null
                  return getGridItem(idx, item, dividerOnMobile)
                })}
              </Grid>
            </Collapse>
            <Grid container justifyContent="center">
              <Button
                variant="text"
                onClick={() => setShowMore(!showMore)}
                endIcon={showMore ? <ChevronUp /> : <ChevronDown />}
                track={{
                  ...{
                    list_name: 'VALUE PROPS ACCORDION LESS/MORE',
                    click_id: showMore ? 'Less' : 'More',
                    click_text: showMore ? 'Show less' : 'Show more',
                  },
                  ...event,
                }}
                data-testid="show-more-less-value-props-button"
              >
                {showMore ? 'Show less' : 'Show all'}
              </Button>
            </Grid>
          </>
        )}
      </>
    )
  }

  return (
    <Section
      backgroundColor={jsonContent?.background ?? backgroundColor}
      className={classNames(
        // styles['section'],
        // { [styles['breakline']]: breakline },
        'section py-12 px-0 sm:flex sm:justify-center lg:py-16 lg:px-0',
        breakline && 'breakline'
      )}
      variant={jsonContent?.background ?? 'bgDefault'}
      contain={isMobile ? marginOnMobile : true}
      data-testid="ValueProps_section"
    >
      <Grid
        container
        justifyContent="center"
        data-testid={`value-prop-grid-container-${idContent}`}
        className={
          // styles['container-grid']
          'group sm:[section_&]:container-grid lg:[section_&]:max-w-full'
        }
        data-no-margin={marginOnMobile}
      >
        <TitleDivider
          data-testid={`value-prop-title-divider-${idContent}`}
          eyebrow={eyebrow}
          eyebrowBlack
          eyebrowClassName={
            // styles['eyebrow']
            ''
          }
          className={
            // styles['title-divider-wrapper']
            '[*>&]:p-0 [section-breakline_&]:[container-grid_&]:[&_h2]:p-0'
          }
          title={title}
          subtitle={subtitle}
          isMobile={isMobile}
          variants={variant}
          disableTitleTag={true}
          disableSubtitleTag={true}
        />

        {isMobile && carouselOnMobile ? (
          //only on mobile and carouselOnMobile set to true
          <Carousel
            className={
              // styles['flat-carousel-wrapper']
              'group-data-[no-margin="false"]:p-0'
            }
            carouselItems={grid?.gridItemsCollection?.items}
            itemComponent={Card}
            variant="preview-small-xs"
            idPrefix="value-props-carousel"
            withIndicators
            classNameIndicators={
              // styles['indicators']
              '[&_div]:bg-[#c0cbd8]'
            }
            classNameActiveDot={
              // styles['active-indicator']
              'bg-[#3300ff] opacity-100'
            }
          />
        ) : containerGrid ? (
          <Grid
            container
            data-testid={`value-prop-grid-card-container-${idContent}`}
            className={classNames(
              styles['props-container'],
              // styles['card-grid'],
              // { [styles['breakline']]: breakline })}
              jsonContent?.variant === 'facts' ? 'md:gap-y-16 md:gap-x-4 gap-0' : 'gap-0 md:gap-4',
              'flex flex-row justify-between items-stretch lg:flex-row lg:justify-center',
              'breakline'
            )}
            data-variant={jsonContent?.variant || 'standard'}
          >
            {gridItems(dividerOnMobile, isMobile ? maxMobileItems : 0)}
          </Grid>
        ) : (
          // only for aboutUs culture
          <Grid
            item
            sm={10}
            md={8}
            lg={12}
            data-testid={`value-prop-grid-card-container-${idContent}`}
            className={classNames(
              // styles[isMobile ? 'card-grid-column' : 'card-grid'],
              styles['props-container'],
              isMobile
                ? 'flex flex-col justify-between items-stretch gap-4'
                : 'flex flex-row justify-between items-stretch gap-4',
              breakline && 'breakline'
            )}
            // [styles['breakline']]: breakline,
            data-variant={jsonContent?.variant || 'standard'}
          >
            {gridItems(false, isMobile ? maxMobileItems : 0)}
          </Grid>
        )}
        {linkButton && (
          <div
            className={
              // styles['button-wrapper']
              'flex flex-col justify-center items-center w-full mt-12 lg:mt-16'
            }
          >
            <LinkButton
              className={
                // styles['button']
                'min-w-[293px] h-[47px]'
              }
              content={linkButton}
              typographicOptions={{
                variantOverride: { [BLOCKS.PARAGRAPH]: 'bodyLg' },
                fontWeight: 'bold',
                'data-testid': `value-prop-button-text-${idContent}`,
                className:
                  // styles['typography']
                  'min-w-[105px] text-center',
              }}
              color={buttonColor ? buttonColor : 'primary'}
              data-testid={`value-prop-button-${idContent}`}
              track={{
                ...{
                  list_name: 'Personalized Value Props CTA',
                  click_id: linkButton.linkText?.json.content?.[0]?.content[0]?.value,
                  click_text: linkButton.linkText?.json.content[0]?.content[0]?.value,
                  click_url: linkButton.linkHref,
                  nav_link_section: navLinkSection ?? 'Value Props',
                  click_position: '0',
                  track_event: 'internal_campaign_click',
                  event_action: 'button_click',
                  event_type: 'button_click',
                },
                ...linkButton.event,
              }}
            />
            {arrow && handwritten && (
              <>
                <div
                  className={
                    // styles['arrow']
                    'relative rotate-[372deg] ml-[-176px] mt-4 lg:ml-[-160px]'
                  }
                >
                  <MediaImageStandard
                    alt={arrow?.mediaAltText || 'Arrow'}
                    content={arrow.mediaContent}
                    layout="fixed"
                    height="30"
                    width="25"
                  />
                </div>
                <div
                  className={
                    // styles['handwritten']
                    'mt-[-24px] lg:mt-[-22.4px] lg:ml-0'
                  }
                >
                  <MediaImageStandard
                    alt={handwritten?.mediaAltText || 'Handwrite'}
                    content={handwritten.mediaContent}
                    layout="fixed"
                    width="130"
                    height="30"
                  />
                </div>
              </>
            )}
          </div>
        )}

        {disclaimer && (
          <Typography
            content={disclaimer?.textContent}
            variant="bodyXs"
            className="max-w-[808px] text-center text-[#42546b] m-auto pt-12"
            data-testid={`value-prop-disclaimer-${idContent}`}
          />
        )}
      </Grid>
    </Section>
  )
}

function ValueProps({ content }) {
  const idContent = content?.fields?.sys?.id
  const {
    title,
    eyebrow,
    subtitle,
    disclaimer,
    grid,
    event,
    theme,
    linkButton,
    arrow,
    handwritten,
    backgroundConfigJson,
  } = useMemoizedContentGetter(content, [
    'title',
    'eyebrow',
    'subtitle',
    'disclaimer',
    'grid',
    'event',
    'theme',
    'linkButton',
    'arrow',
    'handwritten',
    'backgroundConfigJson',
  ])
  const fieldName = content?.fields?.fieldName
  return (
    <ValuePropsModule
      idContent={idContent}
      title={title}
      eyebrow={eyebrow}
      subtitle={subtitle}
      disclaimer={disclaimer}
      grid={grid}
      event={event}
      theme={theme}
      linkButton={linkButton}
      arrow={arrow}
      handwritten={handwritten}
      backgroundConfigJson={backgroundConfigJson}
      componentVariant={fieldName}
    />
  )
}

export { ValueProps, ValuePropsModule }

import React from 'react'
import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import { AchieveTheme, Box } from '@achieve/ascend'
import { Typography, MediaImageStandard } from 'components/Contentful'
import { Eyebrow } from 'components/Eyebrow'
import { get as _get } from 'lodash-es'
import classNames from 'classnames'

function TrustMarkersSectionModule({
  title,
  eyebrow,
  subtitle,
  style,
  trustMarkersGrid,
  jsonStyleOverride,
  backgroundColor,
}) {
  const styleOverrideMap = {
    default: 'default',
    blue: 'blue1',
    blue2: 'blue2',
    black: 'black1',
  }

  const backgroundColorMap = {
    'light-blue': AchieveTheme?.ascend?.colors?.neutral[25],
    white: AchieveTheme?.ascend?.colors?.neutral?.[0],
    grey: AchieveTheme?.ascend?.colors?.blue?.[50],
  }

  const StylesMarkers = {
    default: 'default',
    blue1: 'blue1',
    blue2: 'blue2',
    black1: 'black1',
  }

  style = styleOverrideMap[jsonStyleOverride] ?? style

  let styleSection =
    StylesMarkers[
      _get(style, 'textContent.json.content[0].content[0].value', StylesMarkers.default)
    ]

  const backgroundSection = () => {
    switch (styleSection) {
      case StylesMarkers.blue1 || StylesMarkers.blue2:
        return AchieveTheme?.ascend?.colors?.blue[500]
      case StylesMarkers.black1:
        return AchieveTheme?.ascend?.colors?.neutral?.[0]
      default:
        return AchieveTheme?.ascend?.colors?.neutral[25]
    }
  }
  const subtitleVariant = (mobileVariant) => {
    switch (styleSection) {
      case StylesMarkers.blue1 || StylesMarkers.blue2:
        return mobileVariant ? 'bodyLg' : 'headingMd'
      case StylesMarkers.black1:
        return 'bodySm'
      default:
        return mobileVariant ? 'displayMd' : 'displayLg'
    }
  }
  const subtitleFontWeight = (mobileVariant) => {
    switch (styleSection) {
      case StylesMarkers.blue1 || StylesMarkers.blue2:
        return mobileVariant ? 'regular' : 'bold'
      case StylesMarkers.black1:
        return 'regular'
      default:
        return 'bold'
    }
  }
  const titleMarkerWeight = () => {
    switch (styleSection) {
      case StylesMarkers.blue1:
        return 'font-light lg:font-medium'
      case StylesMarkers.black1:
        return 'font-bold lg:font-medium'
      default:
        return 'font-bold'
    }
  }
  const subtitleMarkerVariant = () => {
    if (styleSection === StylesMarkers.black1) {
      return 'bodySm'
    }
    return 'bodySm'
  }

  return (
    <Section
      backgroundColor={backgroundColorMap[backgroundColor] ?? backgroundSection()}
      data-testid="trust-markers-section"
      contain={false}
      className={''}
    >
      <Box
        className={classNames(
          'trust-markers-content group flex flex-col items-center px-12 py-4 text-center data-[marker-style="black1"]:py-0 data-[marker-style="black1"]:px-8 data-[marker-style="blue1"]:py-12 data-[marker-style="blue1"]:px-4 lg:data-[marker-style="blue1"]:py-16 lg:data-[marker-style="blue1"]:px-4 lg:data-[marker-style="black1"]:pt-8 lg:data-[marker-style="black1"]:px-0 lg:data-[marker-style="black1"]:pb-16 lg:data-[marker-style="blue2"]:pb-16 '
        )}
        data-marker-style={styleSection}
        data-testid="trust-markers-content"
      >
        {eyebrow?.textContent?.json && (
          <Eyebrow
            content={eyebrow?.textContent}
            black={true}
            className={'mt-2 pb-0 displayXs'}
            variant="displayXS30"
          />
        )}
        {title?.textContent?.json && (
          <Eyebrow
            content={title?.textContent?.json}
            className={`trust-markers-title ${
              StylesMarkers.blue2 === styleSection ? 'text-[#42546B]' : 'text-[#3300ff]'
            }  py-6 `}
            variant="bodySm"
            fontWeight="bold"
          />
        )}
        {subtitle?.textContent?.json && (
          <Typography
            className={'trust-markers-subtitle group-data-[marker-style="blue1"]:text-white'}
            content={subtitle?.textContent?.json}
            variant={subtitleVariant(false)}
            mobileVariant={subtitleVariant(true)}
            breakpoint="lg"
            responsive={true}
            fontWeight={subtitleFontWeight()}
          />
        )}
        <Box
          className={
            'trust-markers-markers flex flex-col w-full max-w-[425px] lg:flex-row lg:w-auto lg:max-w-[initial]'
          }
          data-testid="trust-markers-markers-container"
        >
          {trustMarkersGrid &&
            trustMarkersGrid?.gridItemsCollection?.items?.map((marker, index) => {
              return (
                <Box
                  className={classNames(
                    'trust-markers-item flex flex-col items-center mt-8 pb-12 border-b border-t-0 border-x-0 border-solid border-neutral-300 last:border-b-0',
                    'group-data-[marker-style="blue1"]:pb-8 group-data-[marker-style="blue1"]:border-b group-data-[marker-style="blue1"]:border-t-0 group-data-[marker-style="blue1"]:border-x-0 group-data-[marker-style="blue1"]:border-solid group-data-[marker-style="blue1"]:border-[#94bbfe]',
                    'lg:border-b-0 lg:border-r lg:border-y-0 lg:border-l-0 lg:border-solid lg:border-neutral-300 lg:last:border-r-0 lg:py-0 lg:px-16',
                    'group-data-[marker-style="blue1"]:lg:border-b-0 group-data-[marker-style="blue1"]:lg:border-r group-data-[marker-style="blue1"]:lg:border-y-0 group-data-[marker-style="blue1"]:lg:border-l-0 group-data-[marker-style="blue1"]:lg:border-solid group-data-[marker-style="blue1"]:lg:border-[#94bbfe] group-data-[marker-style="blue1"]:lg:last:border-r-0',
                    'group-data-[marker-style="black1"]:lg:border-r group-data-[marker-style="black1"]:lg:border-[#c0cbd8] group-data-[marker-style="black1"]:lg:border-y-0 group-data-[marker-style="black1"]:lg:last:border-r-0'
                  )}
                  data-testid="trust-markers-item"
                  key={'marker-' + index}
                >
                  {marker?.eyebrow && (
                    <Eyebrow
                      className={'trust-markers-item-eyebrow pb-0'}
                      content={marker?.eyebrow}
                      variant="bodySm"
                      fontWeight="bold"
                      black
                    />
                  )}
                  {!marker?.image && (
                    <>
                      <Typography
                        content={marker?.title}
                        breakpoint="lg"
                        responsive={true}
                        fontWeight={titleMarkerWeight()}
                        className={
                          'trust-markers-item-title text-[#3300ff] mt-4 group-data-[marker-style="black1"]:text-black group-data-[marker-style="black1"]:mt-0 group-data-[marker-style="blue1"]:text-[#fff] group-data-[marker-style="blue1"]:lg:mt-0 displayMd lg:displayXl'
                        }
                        data-testid="trust-markers-item-title"
                      />
                      <Typography
                        content={marker?.subtitle}
                        variant={subtitleMarkerVariant(false)}
                        mobileVariant={subtitleMarkerVariant(true)}
                        breakpoint="lg"
                        responsive={true}
                        className={
                          'trust-markers-item-subtitle mt-4 group-data-[marker-style="black1"]:mt-0 group-data-[marker-style="blue1"]:mt-0 group-data-[marker-style="blue1"]:text-white'
                        }
                        data-testid="trust-markers-item-subtitle"
                      />
                    </>
                  )}
                  {marker?.image && (
                    <div
                      className={
                        'flex flex-col h-full justify-between [&>img]:object-contain [&>img]:mb-2'
                      }
                    >
                      <MediaImageStandard
                        content={marker?.image}
                        layout="fixed"
                        width={300}
                        height={62}
                        alt={marker?.image?.title}
                      />
                      <Typography
                        content={marker?.subtitle}
                        variant={subtitleMarkerVariant(false)}
                        mobileVariant={subtitleMarkerVariant(true)}
                        breakpoint="lg"
                        responsive={true}
                        className={
                          'trust-markers-item-subtitle mt-4 group-data-[marker-style="black1"]:mt-0 group-data-[marker-style="blue1"]:mt-0 group-data-[marker-style="blue1"]:text-white'
                        }
                      />
                    </div>
                  )}
                </Box>
              )
            })}
        </Box>
      </Box>
    </Section>
  )
}

const TrustMarkersSection = ({ content }) => {
  const { title, eyebrow, subtitle, style, trustMarkersGrid } = useMemoizedContentGetter(content, [
    'title',
    'eyebrow',
    'subtitle',
    'style',
    'trustMarkersGrid',
  ])

  const styleOverride = content?.fields?.additionalConfiguration?.fields?.jsonContent?.styleOverride
  const backgroundColor = content?.fields?.additionalConfiguration?.jsonContent?.backgroundColor

  return (
    <TrustMarkersSectionModule
      title={title}
      eyebrow={eyebrow}
      subtitle={subtitle}
      style={style}
      jsonStyleOverride={styleOverride}
      backgroundColor={backgroundColor}
      trustMarkersGrid={trustMarkersGrid}
    />
  )
}
export { TrustMarkersSection, TrustMarkersSectionModule }
export default TrustMarkersSection

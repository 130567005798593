import { useMemoizedContentGetter } from 'utils/contentful'
import { TypographyLongForm as Typography, MediaImageStandard } from 'components/Contentful'
import Section from 'components/Section'
import { Grid, Box, Link } from '@achieve/ascend'
import classNames from 'classnames'
import { ArrowRight } from 'react-feather'
import { Eyebrow } from 'components/Eyebrow'
import { actualDevice, DEVICES } from 'utils/shared'
import useIsSsr from 'hooks/useIsSsr'
import { useState, useEffect, useContext } from 'react'
import { AchieveLink } from 'components/AchieveLink'
import { mainProductConfig } from 'services/contentful/helpers'
import { LayoutContext } from 'providers/LayoutProvider'
import { getSlug } from 'utils/conversions'

function SectionFullBackground({ content }) {
  const isSsr = useIsSsr()
  const [device, setDevice] = useState(null)

  useEffect(() => {
    if (!isSsr) {
      // check If current browser is a safari browser IOS
      setDevice(actualDevice(navigator.userAgent))
    }
  }, [isSsr])

  const {
    title,
    subTitle,
    background,
    link,
    button_google,
    button_apple,
    image_google,
    image_apple,
    eyebrow,
    backgroundConfigJson: { jsonContent },
  } = useMemoizedContentGetter(content, [
    'title',
    'subTitle',
    'background',
    'link',
    'eyebrow',
    'button_google',
    'button_apple',
    'image_google',
    'image_apple',
    'backgroundConfigJson',
  ])
  const { state: { siteEnv } = {} } = useContext(LayoutContext)
  const backgroundObjectPosition = jsonContent?.backgroundObjectPosition ?? 'right'
  const backgroundObjectPositionMobile = jsonContent?.backgroundObjectPositionMobile ?? 'bottom'
  const mobilePadding = jsonContent?.mobilePadding ?? 'bottom'
  return (
    <Section
      className={'[content-visibility:auto]'}
      data-testid="closer-fullbg-section"
      data-narrow={true}
      mobileMediaContent={background?.mobileMediaContent}
      middleMediaContent={background?.mobileMediaContent}
      mediaContent={background?.mediaContent}
      backgroundImageAlt={background?.mediaAltText}
      showPattern={jsonContent?.showPattern}
      backgroundObjectPositionClass={`bg-${getSlug(backgroundObjectPositionMobile)} lg:bg-${getSlug(
        backgroundObjectPosition
      )}`}
      variant={jsonContent?.background ?? 'bgDefault'}
      full={true}
    >
      <Box
        className={classNames(
          mobilePadding === 'bottom' ? 'pt-8 pb-[336px] px-8' : '',
          mobilePadding === 'top' ? 'pt-[336px] pb-8 px-8' : '',
          'relative h-full flex items-center justify-center flex-col text-center md:p-0 lg:max-w-[1007px] lg:w-[1007px] lg:py-8 2xl:max-w-full 2xl:w-full 2xl:m-0'
        )}
        component="div"
      >
        <Grid
          container
          alignItems="center"
          className={jsonContent?.longer ? 'min-h-[520px]' : 'min-h-[280px] md:min-h-[360px]'}
          data-testid="closer-fullbg-outer-grid-container"
        >
          {jsonContent?.textAlign == 'right' && <Grid item xs={4} md={6} lg={7}></Grid>}

          <Grid
            item
            xs={12}
            md={6}
            lg={5}
            className={'text-left'}
            data-testid="closer-fullbg-grid-item-text"
          >
            <div
              className={classNames('px-0 py-4', jsonContent?.textAlign == 'right' && 'md:pl-10')}
              data-testid="closer-fullbg-text-container"
            >
              {eyebrow && (
                <Eyebrow
                  content={eyebrow?.textContent}
                  variant="bodySm"
                  fontWeight="bold"
                  component="h1"
                  className={classNames(
                    'text-left pb-0',
                    !eyebrow?.fontColor ||
                      (eyebrow?.fontColor?.toLowerCase() === 'black' && 'text-[#1d252f]'),
                    eyebrow?.fontColor?.toLowerCase() === 'white' && 'text-white',
                    eyebrow?.fontColor?.toLowerCase() === 'blue' && 'text-[#30f]'
                  )}
                />
              )}
              {jsonContent?.blur && <div></div>}
              {title && (
                <Typography
                  content={title?.textContent}
                  className={classNames(
                    'md:relative md:z-[2] md:mb-4',
                    !title?.fontColor ||
                      (title?.fontColor?.toLowerCase() === 'black' && 'text-[#1d252f]'),
                    title?.fontColor?.toLowerCase() === 'white' && 'text-white',
                    title?.fontColor?.toLowerCase() === 'blue' && 'text-[#30f]'
                  )}
                  data-testid="section-full-background-title"
                />
              )}
              {subTitle && (
                <Typography
                  content={subTitle?.textContent}
                  className={classNames(
                    'md:relative md:z-[2] md:mb-4',
                    !subTitle?.fontColor ||
                      (subTitle?.fontColor?.toLowerCase() === 'black' && 'text-[#1d252f]'),
                    subTitle?.fontColor?.toLowerCase() === 'white' && 'text-white',
                    subTitle?.fontColor?.toLowerCase() === 'blue' && 'text-[#30f]'
                  )}
                  data-testid="section-full-background-subtitle"
                />
              )}
            </div>
            {link && (
              <Link
                underline="hover"
                fontWeight="bold"
                variant="bodyLg"
                href={link?.linkHref}
                className="flex flex-row items-center gap-1"
              >
                <Typography
                  content={link?.linkText}
                  fontWeight="bold"
                  className={'inline'}
                  variant="bodyLg"
                />
                <ArrowRight className={'pt-1'} />
              </Link>
            )}
            {(button_google || button_apple) && (
              <Grid className={'relative h-[38px] z-[2] mt-6 mb-[37.6px]'} container>
                {(device === DEVICES.desktop || device === DEVICES.ios) && image_apple && (
                  <AchieveLink
                    href={button_apple?.linkHref}
                    data-testid="app-store-btn"
                    noLink
                    track={{
                      click_id: image_google?.mediaAltText || 'native-download-google-en',
                      click_text: `${
                        mainProductConfig[siteEnv || 'achieve']?.['MainProductHyphen']
                      } | ${image_google?.mediaAltText || 'native-download-google-en'}`,
                      click_type: 'Button Click',
                      click_position: 1,
                      event_type: 'button_click',
                      nav_link_section: 'Closer Statement',
                      track_event: 'app_download_click',
                    }}
                  >
                    <div className={'h-[38px] w-[118px] mr-4'}>
                      <MediaImageStandard
                        content={image_apple?.mediaContent}
                        alt={image_apple?.mediaAltText || 'apple-button-image'}
                      />
                    </div>
                  </AchieveLink>
                )}
                {(device === DEVICES.desktop || device === DEVICES.android) && image_google && (
                  <AchieveLink
                    href={button_google?.linkHref}
                    data-testid="google-play-btn"
                    noLink
                    track={{
                      click_id: image_apple?.mediaAltText || 'native-download-apple-en',
                      click_text: `${
                        mainProductConfig[siteEnv || 'achieve']?.['MainProductHyphen']
                      } | ${image_apple?.mediaAltText || 'native-download-apple-en'}`,
                      click_type: 'Button Click',
                      click_position: 1,
                      event_type: 'button_click',
                      nav_link_section: 'Closer Statement',
                      track_event: 'app_download_click',
                    }}
                  >
                    <div className={'w-[134px] h-[38px]'}>
                      <MediaImageStandard
                        content={image_google?.mediaContent}
                        alt={image_google?.mediaAltText || 'google-button-image'}
                      />
                    </div>
                  </AchieveLink>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </Section>
  )
}
export { SectionFullBackground }
export default SectionFullBackground

import { Typography } from 'components/Contentful'
import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import styles from './PageTitle.module.scss'

function PageTitle({ content }) {
  const { title } = useMemoizedContentGetter(content, ['title'])

  return (
    <Section className={styles['hero-section']} data-testid="policies-hero-section">
      <Typography
        content={title.textContent}
        className="displayLg lg:displayXl"
      />
    </Section>
  )
}
export { PageTitle }
export default PageTitle
